<script>
  import contrato from './contrato.vue'
  import ContratoAssinaturas from './assinaturas/ContratoAssinaturas'

  import useJwt from '@/auth/jwt/useJwt'

  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import { required } from '@validations'

  export default {
    components: {
      contrato,
      ContratoAssinaturas,
    },
    data() {
      return {
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
        acesso: {
          FinanceiroAssinaturas: false,
        },
      }
    },
    mounted() {
      if (this.userData.role == 'master') {
        this.acesso.FinanceiroAssinaturas = true
      } else {
        this.acesso.FinanceiroAssinaturas = this.userData.GrupoAcesso.FinanceiroAssinaturas
      }
    },
  }
</script>
<template>
  <div>
    <b-tabs>
      <b-tab active>
        <template #title>
          <feather-icon icon="PieChartIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">Contratos</span>
        </template>
        <contrato />
      </b-tab>

      <b-tab v-if="acesso.FinanceiroAssinaturas">
        <template #title>
          <feather-icon icon="TrendingUpIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">Assinaturas</span>
        </template>
        <ContratoAssinaturas />
      </b-tab>
    </b-tabs>
  </div>
</template>
