<script>
  import useJwt from '@/auth/jwt/useJwt'
  import Ripple from 'vue-ripple-directive'
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import { required } from '@validations'
  import BCardCode from '@core/components/b-card-code/BCardCode.vue'
  import { pipe } from 'fp-ts/function'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  import { formatarData, formatarCodigoSequencial, formatarDataHora, formatarValor } from '@/libs/utils'
  import SelecionarAssinaturasUsuario from '@/views/components/usuario/assinatura/SelecionarAssinaturasUsuario.vue'
  import AutenticaSenha from '@/views/pages/auth/autenticaSenha.vue'
  import { async } from 'pdfmake/build/pdfmake'
  import { generateAndDownloadPdf } from '@/utils/geradorPDF/geradorPDF.js'
  import AssinaturaDocumentoRelatorioPaisagem from './components/AssinaturaDocumentoRelatorioPaisagem.vue'

  export default {
    directives: {
      Ripple,
    },
    components: {
      BCardCode,
      AutenticaSenha,
      SelecionarAssinaturasUsuario,
      generateAndDownloadPdf,
      AssinaturaDocumentoRelatorioPaisagem,
    },
    data() {
      return {
        nomeRelatorio: 'Relatório de Assinaturas',
        formatarValor,
        pipe,
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
        user: {
          id: null,
          institutoId: null,
          role: null,
          grupoAcesso: null,
          permissoes: {
            alterarAssinatura: false,
          },
        },
        parametroPDF: {
          dados: [],
          colunas: [],
        },
        table: {
          items: {
            value: [],
            checkedItems: [],
            checkedItemsSolicitar: [],
          },

          fields: [
            { key: 'opcoes', label: 'Alterar' },
            { key: 'assinar', label: 'Assinar' },
            { key: 'solicitar', label: 'Solicitar' },
            {
              key: 'financeiro.codigo',
              label: 'N.º do Contrato',
              sortable: true,
            },
            {
              key: 'financeiro.valorContrato',
              label: 'Valor do Contrato',
              sortable: true,
            },
            {
              key: 'financeiro.valorParcela',
              label: 'Valor da Parcela',
              sortable: true,
            },
            { key: 'gestores', label: 'Gestores', sortable: true },
            { key: 'testemunhas', label: 'Testemunhas', sortable: true },
            {
              key: 'financeiro.tomadorAssinou',
              label: 'Tomador',
              sortable: true,
            },
          ],
          dataToPDF: [],
          fieldsPDF: [
            { key: 'tomador', label: 'Tomador' },
            { key: 'numeroDoContrato', label: 'N.º do Contrato' },
            { key: 'valorContrato', label: 'Valor do Contrato' },
            { key: 'valorParcela', label: 'Valor da Parcela' },
            { key: 'gestores', label: 'Gestores' },
            { key: 'testemunhas', label: 'Testemunhas' },
          ],
          isBusy: false,
          sort: {
            by: '',
            desc: false,
            direction: 'asc',
          },
          styles: {
            status: [
              {
                GRPEPago: 'success',
                GRPEGerado: 'warning',
                GRPEExpirado: 'info',
              },
            ],
          },
        },
        pesquisar: {
          testemunhas: undefined,
          gestores: undefined,
          tomadores: undefined,
          opcoes: {
            value: [
              { value: 'gestor', text: 'Gestor' },
              { value: 'testemunha', text: 'Testemunha' },
              { value: 'tomador', text: 'Tomador' },
            ],
            selected: undefined,
            valor: undefined,
          },
          busy: {
            solicitar: false,
            pesquisar: false,
            assinar: false,
          },
          pagination: {
            currentPage: 1,
            total: 0,
            perPage: 10,
            options: [10, 25, 50, 100],
          },
        },
        assinatura: {
          financeiroId: undefined,
          gestores: [],
          testemunhas: [],
        },
        acesso: {
          FinanceiroAssinaturasConsulta: false,
          FinanceiroAlterarAssinaturas: false,
          FinanceiroAssinaturaDigital: false,
        },
      }
    },
    mounted() {
      this.user.id = this.userData.id
      this.user.institutoId = this.userData.institutoSelecionado
      this.user.role = this.userData.role
      this.user.grupoAcesso = this.userData.GrupoAcesso

      this.user.permissoes.alterarAssinatura =
        this.user.role == 'master' ? true : this.user.grupoAcesso['FinanceiroAlterarAssinaturas']

      if (this.user.role == 'master') {
        this.acesso.FinanceiroAssinaturasConsulta = true
        this.acesso.FinanceiroAlterarAssinaturas = true
        this.acesso.FinanceiroAssinaturaDigital = true
      } else {
        this.acesso.FinanceiroAssinaturasConsulta = this.user.grupoAcesso.FinanceiroAssinaturasConsulta
        this.acesso.FinanceiroAlterarAssinaturas = this.user.grupoAcesso.FinanceiroAlterarAssinaturas
        this.acesso.FinanceiroAssinaturaDigital = this.user.grupoAcesso.FinanceiroAssinaturaDigital
      }

      this.validarPermissaoAlterarAssinaturas()
      this.validarPermissaoAssinaturaDigital()
    },
    computed: {
      totalItems: function () {
        return this.table.items.value.length
      },
      itemChecked: function () {
        return this.table.items.checkedItems.filter((item) => item !== false && item !== null).length > 0
      },
      itemSolicitarChecked: function () {
        return this.table.items.checkedItemsSolicitar.filter((item) => item !== false && item !== null).length > 0
      },
    },
    methods: {
      validarPermissaoAlterarAssinaturas() {
        if (!this.acesso.FinanceiroAlterarAssinaturas) {
          this.table.fields = this.table.fields.filter((item) => item.key !== 'opcoes')
        }
      },
      validarPermissaoAssinaturaDigital() {
        if (!this.acesso.FinanceiroAssinaturaDigital) {
          this.table.fields = this.table.fields.filter((item) => item.key !== 'assinar')
        }
      },
      pesquisas: function () {
        return {
          assinar: async () => {
            for (const item of this.table.items.checkedItems) {
              if (item !== false && item !== undefined) {
                const response = (await this.propostas().assinar(item, this.user.id)).data

                if (response.status != 200) {
                  this.toast().error(response.message)
                } else {
                  this.toast().success('Contrato assinado com sucesso')
                }
              }
            }
          },
          solicitar: async () => {
            for (const item of this.table.items.checkedItemsSolicitar) {
              if (item !== false && item !== undefined) {
                const response = (await this.propostas().solicitar(item, this.user.id)).data

                if (response.status != 200) {
                  this.toast().error(response.message)
                } else {
                  this.toast().success('Solicitação de assinatura enviada com sucesso')
                }
              }
            }
          },
        }
      },
      util: function () {
        return {
          unique: (originalArray) => {
            let uniqueArray = []
            let seenFinanceiroIds = new Set()

            for (let item of originalArray) {
              let financeiroId = item.financeiroId
              if (!seenFinanceiroIds.has(financeiroId)) {
                uniqueArray.push(item)
                seenFinanceiroIds.add(financeiroId)
              }
            }
            return uniqueArray
          },
        }
      },
      tabelas: function () {
        return {
          preencher: async () => {
            try {
              this.pesquisar.busy.pesquisar = true
              const tomador = this.pesquisar.opcoes.selected == 'tomador' ? this.pesquisar.opcoes.valor : undefined
              const testemunha = this.pesquisar.opcoes.selected == 'testemunha' ? this.pesquisar.opcoes.valor : undefined
              const gestor = this.pesquisar.opcoes.selected == 'gestor' ? this.pesquisar.opcoes.valor : undefined

              const data = await this.propostas().listar(
                this.user.id,
                tomador,
                testemunha,
                gestor,
                this.pesquisar.pagination.currentPage,
                this.pesquisar.pagination.perPage,
                this.user.institutoId,
              )

              const unsignedData = data.filter((item) => item.assinouFinanceiro !== true)

              const response = unsignedData.map((item) => {
                return {
                  ...item,
                  gestores: item.financeiro.AssinaturasSobFinanceiro.filter((item) => item.usuario.tipoAssinatura == 'gestor'),
                  testemunhas: item.financeiro.AssinaturasSobFinanceiro.filter(
                    (item) => item.usuario.tipoAssinatura == 'testemunha',
                  ),
                }
              })

              this.table.items.value = this.util().unique(response)
              this.dataToPDF = this.table.items.value.map((item) => {
                return {
                  tomador: item.financeiro.segurado.tomador.nomeCompleto,
                  numeroDoContrato: item.financeiro.codigo,
                  valorContrato: item.financeiro.valorContrato,
                  valorParcela: item.financeiro.valorParcela,
                  gestores: item.gestores.map((gestor) => gestor.usuario.name).join(', '),
                  testemunhas: item.testemunhas.map((testemunha) => testemunha.usuario.name).join(', '),
                }
              })

              this.table.items.checkedItems = [false]
              this.table.items.checkedItemsSolicitar = [false]
            } catch (e) {
              console.error('err', e)
            } finally {
              this.parametroPDF = {
                dados: this.dataToPDF,
                colunas: this.table.fieldsPDF,
              }
              this.pesquisar.busy.pesquisar = false
            }
          },
          editar: async (item) => {
            if (!this.user.permissoes.alterarAssinatura) {
              this.toast().error('Você não tem permissão para alterar assinaturas')
              return
            }

            this.assinatura.testemunhas = item.testemunhas.map((i) => i.usuario)
            this.assinatura.gestores = item.gestores.map((i) => i.usuario)
            this.assinatura.item = item
            this.$refs['assinatura'].show()
          },
        }
      },
      propostas() {
        return {
          listar: async (userId, tomador, testemunha, gestor, page, perPage, institutoId) => {
            return (
              await useJwt.get('financeiro-assinatura', {
                params: {
                  userId,
                  tomador,
                  testemunha,
                  gestor,
                  page,
                  perPage,
                  institutoId: this.user.institutoId,
                },
              })
            ).data
          },
          assinar: async (financeiroId, userId, body) => {
            return await useJwt.put(
              `financeiro-assinatura/${financeiroId}/${userId}`,
              body != undefined
                ? body
                : {
                    assinouFinanceiro: true,
                  },
            )
          },
          solicitar: (financeiroId, userId) => {
            return useJwt.post(`financeiro-assinatura/solicitar`, {
              baseUrl: window.location.origin,
              financeiroId,
              userId,
            })
          },
        }
      },
      toast: function () {
        return {
          success: async (message) => {
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'BellIcon',
                  variant: 'success',
                },
              },
              {
                position: 'top-right',
                timeout: 3000,
              },
            )
          },
          error: async (message) => {
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'BellIcon',
                  variant: 'danger',
                },
              },
              {
                position: 'top-right',
                timeout: 3000,
              },
            )
          },
          info: async (message) => {
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'BellIcon',
                  variant: 'info',
                },
              },
              {
                position: 'top-right',
                timeout: 3000,
              },
            )
          },
        }
      },
      assinaturas() {
        return {
          confirmar: async ({ gestores, testemunhas }, item) => {
            const financeiroId = item.financeiroId
            try {
              for (let index = item.testemunhas.length - 1; index >= 0; index--) {
                const testemunha = item.testemunhas[index]
                const id = testemunha.usuarioId
                const newId = testemunhas[index].id // Certifique-se de que "testemunhas" esteja definido corretamente.
                await this.propostas().assinar(financeiroId, id, {
                  usuarioId: newId,
                })
              }

              for (const [index, gestor] of item.gestores.entries()) {
                const id = gestor.usuarioId
                const newId = gestores[index].id
                await this.propostas().assinar(financeiroId, id, {
                  usuarioId: newId,
                })
              }
            } catch (e) {
              console.error('err', e)
            } finally {
              this.tabelas().preencher()
              this.toast().success('Assinaturas confirmadas com sucesso')
              this.$refs['assinatura'].hide()
            }
          },
          checarTodas: async () => {
            const checkboxes = document.querySelectorAll('[id^="assinarCheckBox"]')

            for (const checkbox of checkboxes) {
              checkbox.click()
            }
          },
        }
      },
      utils() {
        return {
          solicitar: async () => {
            try {
              this.pesquisar.busy.pesquisar = true
              await this.pesquisas().solicitar()
              await this.tabelas().preencher()
            } catch (e) {
              console.error('err', e)
            } finally {
              this.pesquisar.busy.pesquisar = false
            }
          },
          assinar: async (autenticou) => {
            if (!autenticou) {
              return
            }
            await this.pesquisas().assinar()
            this.tabelas().preencher()
            this.$refs['auth'].hide()
          },
          usuarioAssinou(gestores, testemunhas, userId) {
            const data = [...gestores, ...testemunhas]

            const user = data.find((item) => item.usuarioId == userId)

            if (!user) {
              return false
            }
            return user.assinouFinanceiro
          },
          generateCode(data, codigo) {
            // Extract the last two digits of the year from the data string
            let year = data.substring(0, 4).slice(-2)

            // Ensure the codigo value is 6 digits long by padding it
            let codigoStr = String(codigo).padStart(6, '0')

            // Join the two extracted values with a forward slash in between
            return `${codigoStr}/${year}`
          },
        }
      },

      exportToPDF() {
        if (!this.table.items.value.length) {
          this.$message.error('Nenhum registro encontrado!')

          return
        }
        if (this.$refs.tableAssinaturas) {
          let idInstituto = this.userData.institutoSelecionado
          let htmlContent = this.$refs.exportarPDF.$children[0].$refs.html2PdfLandscape.$el.innerHTML
          let periodoFiltro = null
          let relatorioPaisagem = true
          let nomeInstituto = this.userData.Instituto.nome
          let nomeRelatorio = this.nomeRelatorio
          let informacoesInstituto = `
                      ${this.userData.Instituto.logradouro},
                      ${this.userData.Instituto.numero},
                      ${this.userData.Instituto.bairro},
                      ${this.userData.Instituto.cep} - ${this.userData.Instituto.cidade} - ${this.userData.Instituto.uf}
                      E-mail: ${this.userData.Instituto.emailResponsavel} - Fone:${this.userData.Instituto.telefone}
                    `
          generateAndDownloadPdf(
            this,
            htmlContent,
            nomeInstituto,
            periodoFiltro,
            nomeRelatorio,
            informacoesInstituto,
            relatorioPaisagem,
            idInstituto,
          )
        } else {
          console.error('Elemento da tabela não encontrado')
        }
      },
    },
  }
</script>

<template>
  <b-card-code no-body v-if="acesso.FinanceiroAssinaturasConsulta">
    <b-card-body>
      <!-- Pesquisa -->
      <b-row>
        <b-col md="2">
          <b-form-group label="Pesquisar Por" class="ml-1" label-for="opcao">
            <b-form-select id="opcao" :options="pesquisar.opcoes.value" v-model="pesquisar.opcoes.selected" />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group label="Descrição" class="mr-2" label-for="descricao">
            <b-form-input
              id="descricao"
              placeholder="Descrição"
              v-model="pesquisar.opcoes.valor"
              @keydown.enter="
                pipe(
                  () => {
                    pesquisar.busy.pesquisar = true
                  },
                  () => tabelas().preencher(),
                )
              "
            />
          </b-form-group>
        </b-col>

        <b-button
          ref="dropdown"
          split
          text="Pesquisar"
          class="mr-2"
          :disabled="pesquisar.busy.pesquisar"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          style="margin-top: 21px; width: 160px; height: 40px"
          @click="
            pipe(
              () => {
                pesquisar.busy.pesquisar = true
              },
              () => tabelas().preencher(),
            )
          "
        >
          Pesquisar
        </b-button>
        <b-button
          class="mr-2"
          :disabled="!itemSolicitarChecked || pesquisar.busy.pesquisar"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          style="margin-top: 21px; width: 160px; height: 40px; padding: 0px !important"
          @click="utils().solicitar()"
        >
          Solicitar Assinatura
        </b-button>
        <b-button
          v-if="acesso.FinanceiroAssinaturaDigital"
          class="mr-2"
          :disabled="!itemChecked || pesquisar.busy.pesquisar"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          style="margin-top: 21px; width: 160px; height: 40px"
          @click="$refs['auth'].show()"
        >
          Assinar
        </b-button>
        <b-button
          class="mr-2"
          :disabled="!table.items.value.length"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          style="margin-top: 21px; width: 160px; height: 40px"
          @click="exportToPDF()"
        >
          Gerar Relatório
        </b-button>
      </b-row>
    </b-card-body>

    <b-table
      v-if="table.items.value.length > 0"
      id="tableAssinaturas"
      ref="tableAssinaturas"
      striped
      responsive
      class="position-relative"
      :per-page="pesquisar.pagination.perPage"
      :items="table.items.value"
      :fields="table.fields"
      :sort-by.sync="table.sort.by"
      :sort-desc.sync="table.sort.desc"
      :sort-direction="table.sort.direction"
      :busy="pesquisar.busy.pesquisar"
      :current-page="pesquisar.pagination.currentPage"
    >
      <template #empty="scope">
        <h3 class="text-black text-center mb-2">Nenhum registro encontrado.</h3>
      </template>

      <template #head(assinar)="row">
        <div class="d-flex">
          Assinar
          <feather-icon
            style="cursor: pointer"
            class="ml-1"
            @click="assinaturas().checarTodas()"
            color="blue"
            icon="CheckCircleIcon"
            size="18"
          />
        </div>
      </template>
      <template #cell(opcoes)="row">
        <b-button
          :disabled="!user.permissoes.alterarAssinatura"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="btn-icon"
          @click="tabelas().editar(row.item)"
        >
          <feather-icon icon="EditIcon" />
        </b-button>
      </template>
      <template v-slot:table-busy>
        <div class="text-center my-2">
          <b-spinner class="align-middle mr-1"></b-spinner>
          <strong>Carregando...</strong>
        </div>
      </template>
      <template #cell(financeiro.codigo)="row">
        <div style="font-weight: bold; font-size: 16px">
          {{ row.item.financeiro.codigo }}
        </div>
      </template>
      <template #cell(select)="row">
        <template>
          <b-form-checkbox :key="row.item.id" :value="row.item.id" v-model="itemCheckSelecionado"> </b-form-checkbox>
        </template>
      </template>
      <template #cell(gestores)="row">
        <template v-for="gestor in row.item.gestores">
          <div style="display: flex">
            <span style="width: max-content; margin-right: 5px"> {{ gestor.usuario.name }}</span>
            <feather-icon
              v-if="gestor.assinouFinanceiro === true"
              icon="CheckCircleIcon"
              color="green"
              size="22"
              :id="'gestorLink' + gestor.usuarioId + row.item.financeiroId"
            />
            <feather-icon
              v-else
              :id="'gestorLink' + gestor.usuarioId + row.item.financeiroId"
              icon="XCircleIcon"
              color="red"
              size="22"
            />
          </div>
        </template>
      </template>
      <template #cell(testemunhas)="row">
        <div class="tw-flex">
          <template v-for="testemunha in row.item.testemunhas">
            <span style="width: max-content; margin-right: 5px"> {{ testemunha.usuario.name }}</span>
            <feather-icon
              v-if="testemunha.assinouFinanceiro === true"
              icon="CheckCircleIcon"
              :id="'testemunhaLink' + testemunha.usuarioId + row.item.financeiroId"
              color="green"
              class="tw-mr-2"
              size="22"
            />
            <feather-icon
              v-else
              :id="'testemunhaLink' + testemunha.usuarioId + row.item.financeiroId"
              class="tw-mr-1"
              icon="XCircleIcon"
              color="red"
              size="22"
            />
            <b-tooltip
              placement="bottom"
              :target="'testemunhaLink' + testemunha.usuarioId + row.item.financeiroId"
              title="Tooltip title"
              triggers="hover"
            >
              {{ testemunha.usuario.name }}
            </b-tooltip>
          </template>
        </div>
      </template>
      <template #cell(assinouFinanceiro)="row">
        <feather-icon v-if="row.item.assinouFinanceiro === true" icon="CheckCircleIcon" color="green" size="22" />
        <feather-icon v-else icon="XCircleIcon" color="red" size="22" />
      </template>
      <template #cell(financeiro.valorContrato)="row">
        {{ row.item.financeiro.valorContrato ? formatarValor(row.item.financeiro.valorContrato) : formatarValor(0) }}
      </template>
      <template #cell(financeiro.valorParcela)="row">
        {{ row.item.financeiro.valorParcela ? formatarValor(row.item.financeiro.valorParcela) : formatarValor(0) }}
      </template>
      <template #cell(financeiro.tomadorAssinou)="row">
        <div style="display: flex">
          <span style="width: max-content; margin-right: 5px"> {{ row.item.financeiro.segurado.tomador.nomeCompleto }}</span>
          <feather-icon
            v-if="row.item.financeiro.tomadorAssinou == true"
            icon="CheckCircleIcon"
            color="green"
            size="22"
            :id="'tomador' + row.index + row.item.financeiroId"
          />
          <feather-icon v-else icon="XCircleIcon" :id="'tomador' + row.index + row.item.financeiroId" color="red" size="22" />
        </div>
      </template>
      <template #cell(assinar)="row">
        <b-form-checkbox
          :key="row.item.financeiroId"
          :value="row.item.financeiroId"
          :disabled="utils().usuarioAssinou(row.item.gestores, row.item.testemunhas, user.id)"
          :id="'assinarCheckBox' + row.item.financeiroId"
          v-model="table.items.checkedItems[row.item.financeiroId]"
        >
        </b-form-checkbox>
      </template>
      <template #cell(solicitar)="row">
        <b-form-checkbox
          :key="row.item.financeiroId"
          :value="row.item.financeiroId"
          :disabled="row.item.financeiro.tomadorAssinou === true"
          v-model="table.items.checkedItemsSolicitar[row.item.financeiroId]"
        >
        </b-form-checkbox>
      </template>
    </b-table>

    <b-card-body v-if="table.items.value.length > 0" class="d-flex justify-content-between flex-wrap pt-0">
      <!-- page length -->
      <b-form-group
        style="align-items: center"
        label="Por Página"
        label-cols="6"
        label-align="left"
        label-size="sm"
        label-for="sortBySelect"
        class="text-nowrap mb-md-0 mr-1"
      >
        <b-form-select
          id="perPageSelect"
          v-model="pesquisar.pagination.perPage"
          size="sm"
          inline
          :options="pesquisar.pagination.options"
        />
      </b-form-group>

      <!-- pagination -->
      <b-pagination
        v-model="pesquisar.pagination.currentPage"
        :total-rows="totalItems"
        :per-page="pesquisar.pagination.perPage"
        first-number
        last-number
        prev-class="prev-item"
        next-class="next-item"
        class="mb-0"
      >
        <template #prev-text>
          <feather-icon icon="ChevronLeftIcon" size="18" />
        </template>
        <template #next-text>
          <feather-icon icon="ChevronRightIcon" size="18" />
        </template>
      </b-pagination>
    </b-card-body>
    <!-- INICIO DO VERIFICA SENHA -->
    <b-modal id="auth" ref="auth" centered size="sm" title="Autenticação" hide-footer>
      <AutenticaSenha @autenticarEvent="(autenticou) => utils().assinar(autenticou)" />
    </b-modal>
    <!-- ASD -->
    <b-modal id="assinatura" ref="assinatura" centered size="sm" no-close-on-backdrop title="Assinaturas" hide-footer>
      <SelecionarAssinaturasUsuario
        @confirm="(equipes) => assinaturas().confirmar(equipes, assinatura.item)"
        :institutoId="user.institutoId"
        :gestoresSelecionados="assinatura.gestores"
        :testemunhasSelecionadas="assinatura.testemunhas"
      />
    </b-modal>
    <div>
      <AssinaturaDocumentoRelatorioPaisagem ref="exportarPDF" :parametro="parametroPDF" id="exportarPDF" />
    </div>
  </b-card-code>

  <b-card class="text-center" v-else>
    <b-card-body>
      <b-icon icon="exclamation-triangle" variant="danger" font-scale="2"></b-icon>
      <b-card-title class="text-danger mt-2">Acesso Negado!</b-card-title>
      <b-card-text class="mb-1"> Parece que seu usuário não tem permissão para realizar pesquisas. </b-card-text>
      <b-card-text class="mb-1">
        Para obter acesso, por favor, solicite assistência ou permissões adicionais ao administrador do sistema.
      </b-card-text>
    </b-card-body>
  </b-card>
</template>
