<template>
  <div>
    <b-row id="linhaIndicadores">
      <b-col sm="12" md="3">
        <b-card>
          <CardIndicador
            id="margemEfetivo"
            :isLoading="isLoadingIndicadores"
            title="Contratos consolidados"
            :total="chartContratosConsolidados"
            :series="hardcodedSeries([10, 12, 14])"
          />
        </b-card>
      </b-col>

      <b-col sm="12" md="3">
        <b-card>
          <CardIndicador
            id="margemEfetivo"
            :isLoading="isLoadingIndicadores"
            title=" Valor total de remessas"
            :total="chartTotalRemessas"
            :series="hardcodedSeries([10, 12, 14])"
            barColor="#CDDC39"
          />
        </b-card>
      </b-col>

      <b-col sm="12" md="3">
        <b-card>
          <CardIndicador
            id="margemEfetivo"
            :isLoading="isLoadingIndicadores"
            title="Valor histórico de lucro líquido"
            :total="chartLucroLiquido"
            :series="hardcodedSeries([10, 12, 14])"
            barColor="#689F38"
          />
        </b-card>
      </b-col>

      <b-col sm="12" md="3">
        <b-card>
          <CardIndicador
            id="margemEfetivo"
            :isLoading="isLoadingIndicadores"
            title="Valor histórico total de juros"
            :total="chartTotalJuros"
            :series="hardcodedSeries([10, 12, 14])"
            barColor="#E91E63"
          />
        </b-card>
      </b-col>
    </b-row>
    <b-card-code no-body>
      <b-card-body v-if="acesso.FinanceiroContratosConsulta">
        <!-- Pesquisa -->
        <b-row>
          <b-col md="2">
            <b-form-group label="Pesquisar Por" label-for="opcao">
              <b-form-select id="opcao" :options="tipoOpcoes" v-model="pesquisar.tipoOpcoes" />
            </b-form-group>
          </b-col>
          <b-col md="2">
            <b-form-group label="Status" label-for="status">
              <b-form-select id="status" :options="carregaOpcoesDeStatus()" v-model="pesquisar.statusOpcoes" />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group label="Descrição" label-for="descricao">
              <b-form-input
                id="descricao"
                placeholder="Descrição"
                v-model="pesquisar.descricao"
                v-on:keydown.enter="carregarGrid"
              />
            </b-form-group>
          </b-col>
          <b-col md="2">
            <b-button
              :disabled="isBusy"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              style="margin-top: 21px; width: 160px"
              @click="carregarGrid()"
            >
              {{ !isBusy ? 'Pesquisar' : '' }}
              <b-spinner small v-if="isBusy" />
            </b-button>
          </b-col>
        </b-row>
      </b-card-body>

      <b-card class="text-center" v-else>
        <b-card-body>
          <b-icon icon="exclamation-triangle" variant="danger" font-scale="2"></b-icon>
          <b-card-title class="text-danger mt-2">Acesso Negado!</b-card-title>
          <b-card-text class="mb-1"> Parece que seu usuário não tem permissão para realizar pesquisas. </b-card-text>
          <b-card-text class="mb-1">
            Para obter acesso, por favor, solicite assistência ou permissões adicionais ao administrador do sistema.
          </b-card-text>
        </b-card-body>
      </b-card>

      <b-table
        responsive
        striped
        v-show="items.length > 0"
        :borderless="borderless"
        class="position-relative"
        :items="items"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :busy="isBusy"
        :per-page="pesquisar.perPage"
        :current-page="pesquisar.currentPage"
      >
        <template v-slot:table-busy>
          <div class="text-center my-2">
            <b-spinner class="align-middle mr-1"></b-spinner>
            <strong>Carregando...</strong>
          </div>
        </template>

        <template #cell(opcoes)="row">
          <b-dropdown variant="link" no-caret toggle-class="p-0" left :popper-opts="{ positionFixed: true }">
            <template #button-content>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="btn-icon"
                title="Este botão contém as opções disponíveis para este registro"
              >
                <feather-icon icon="SettingsIcon" />
              </b-button>
            </template>

            <b-dropdown-item @click="contratosPendentes(row.item.id)" v-if="row.item.statusFinanceiro === 'Pendente'">
              <feather-icon icon="EyeIcon" size="16" />
              <span class="align-middle ml-50">Visualizar Contrato</span>
            </b-dropdown-item>

            <b-dropdown-item @click="contratosConsolidados(row.item.id)" v-if="row.item.statusFinanceiro != 'Pendente'">
              <feather-icon icon="CheckCircleIcon" size="16" />
              <span class="align-middle ml-50">
                {{ row.item.statusFinanceiro === 'Consolidado' ? 'Contratos Consolidados' : 'Abrir Contrato' }}
              </span>
            </b-dropdown-item>

            <b-dropdown-item v-if="row.item.statusFinanceiro === 'Pendente'" @click="cancelarContrato(row.item)">
              <feather-icon icon="XIcon" size="16" />
              <span class="align-middle ml-50">
                {{ 'Cancelar Contrato' }}
              </span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

        <template #cell(nomeCompleto)="row">
          {{ row.item.proposta.segurado.tomador.nomeCompleto }}
        </template>

        <template #cell(matricula)="row">
          {{ row.item.proposta.segurado.matricula }}
        </template>

        <template #cell(proposta.valorComIOF)="row">
          {{ row.item.proposta.valorComIOF ? formatarValor(row.item.proposta.valorComIOF) : formatarValor(0) }}
        </template>

        <template #cell(valorParcela)="row">
          {{ row.item.valorParcela ? formatarValor(row.item.valorParcela) : formatarValor(0) }}
        </template>

        <template #cell(numeroParcelas)="row">
          {{ row.item.numeroParcelas }}
        </template>

        <template #cell(statusFinanceiro)="row">
          <b-badge pill :variant="status[0][row.item.statusFinanceiro]">
            {{ formatarStatus(row.item.statusFinanceiro) }}
          </b-badge>
        </template>
      </b-table>

      <b-card-body v-if="items.length > 0" class="d-flex justify-content-between flex-wrap pt-0">
        <!-- page length -->
        <b-form-group
          style="align-items: center"
          label="Por Página"
          label-cols="6"
          label-align="left"
          label-size="sm"
          label-for="sortBySelect"
          class="text-nowrap mb-md-0 mr-1"
        >
          <b-form-select id="perPageSelect" v-model="pesquisar.perPage" size="sm" inline :options="pageOptions" />
        </b-form-group>

        <!-- pagination -->
        <b-pagination
          v-model="pesquisar.currentPage"
          :total-rows="totalRows"
          :per-page="pesquisar.perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          class="mb-0"
        >
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination>
      </b-card-body>

      <!-- Contrato -->
      <b-modal id="modal-contrato" ref="modal-contrato" centered size="xl" hide-footer>
        <contrato-contrato
          @reloadComponent="reloadContratoContrato"
          :key="contratoContratoKey"
          v-if="contrato"
          :parametro="contrato"
        />
      </b-modal>
    </b-card-code>
  </div>
</template>

<script>
  import BCardCode from '@core/components/b-card-code/BCardCode.vue'
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import { required } from '@validations'
  import Ripple from 'vue-ripple-directive'
  import useJwt from '@/auth/jwt/useJwt'
  import {
    formatarData,
    formatarCodigoSequencial,
    formatarDataHora,
    formatarValor,
    formatarValorPorcentagem,
  } from '@/libs/utils'
  import ContratoContrato from '../contrato/contratoContrato.vue'
  import VueApexCharts from 'vue-apexcharts'

  var cardIndicadorMinHeight = 0

  const CardIndicador = {
    props: {
      isLoading: {
        type: Boolean,
        default: false,
      },
      title: {
        type: String,
      },
      total: {
        type: String,
        default: 'R$ 0,00',
      },
      series: {
        type: Array,
        default: null,
      },
      barColor: {
        type: String,
        default: '#2196F3',
      },
    },
    components: {
      VueApexCharts,
    },
    data() {
      return {
        nomeRelatorio: { nome: 'Relatório de Proposta' },

        chartOptions: {
          chart: {
            type: 'bar',
            toolbar: {
              show: false,
            },
          },
          grid: {
            show: false,
            padding: {
              left: 0,
              right: 0,
              top: -15,
              bottom: -15,
            },
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '45%',
              startingShape: 'rounded',
              endingShape: 'rounded',
              borderRadius: 5,
              borderRadiusApplication: 'around',
            },
          },
          legend: {
            show: false,
          },
          dataLabels: {
            enabled: false,
          },
          colors: [this.barColor],
          xaxis: {
            labels: {
              show: false,
            },
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
          },
          yaxis: {
            show: false,
          },
          tooltip: {
            enabled: false,
            x: { show: false },
            y: { show: false },
          },
        },
      }
    },
    mounted() {
      this.checkTitleHeight()
    },
    methods: {
      boldRowClass(item, type) {
        return item.negrito ? 'font-weight-bold' : ''
      },
      checkTitleHeight() {
        if (this.$refs.title.offsetHeight > cardIndicadorMinHeight) {
          cardIndicadorMinHeight = this.$refs.title.offsetHeight
        }
      },
    },
    render() {
      return (
        <b-card no-body>
          <b-card-body class="pb-0">
            <strong class="d-inline-block" id="title" ref="title" style={`min-height:${cardIndicadorMinHeight}px`}>
              {this.title}
            </strong>
            {this.isLoading && <b-spinner big class="d-block mt-1 mb-2" />}
            {!this.isLoading && (
              <div style="position: relative;">
                <h1 class="font-weight-bolder mb-0 py-1" style="z-index:2;">
                  {this.total}
                </h1>
                <div style="position: absolute; right: 0; bottom:0; width: 70px; opacity: .9">
                  <vue-apex-charts height="50" options={this.chartOptions} series={this.series} />
                </div>
              </div>
            )}
          </b-card-body>
        </b-card>
      )
    },
  }
  export default {
    components: {
      BCardCode,
      ValidationProvider,
      ValidationObserver,
      ContratoContrato,
      formatarData,
      formatarValor,
      formatarCodigoSequencial,
      CardIndicador,
    },
    directives: {
      Ripple,
    },
    data() {
      return {
        contratoContratoKey: 0,
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
        isBusy: false,
        formatarData,
        formatarDataHora,
        formatarCodigoSequencial,
        formatarValor,
        tipoOpcoes: [
          { value: 1, text: 'Nome' },
          { value: 2, text: 'Matrícula' },
          { value: 3, text: 'Nº Contrato' },
        ],
        statusOpcoes: [
          { value: '', text: 'Todos' },
          { value: 'Pendente', text: 'Pendente' },
          { value: 'Consolidado', text: 'Consolidado' },
          { value: 'Quitado', text: 'Quitado' },
          { value: 'Cancelado', text: 'Cancelado' },
          { value: 'Segurado Exonerado', text: 'Segurado Exonerado' },
          { value: 'Segurado Falecido', text: 'Segurado Falecido' },
          { value: 'Suspensão Judicial', text: 'Suspensão Judicial' },
        ],
        pesquisar: {
          tipoOpcoes: 1,
          descricao: '',
          perPage: 10,
          currentPage: 1,
          statusOpcoes: '',
        },
        required,
        pageOptions: [10, 25, 50, 100],
        totalRows: 1,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        fields: [
          { key: 'opcoes', label: 'Opções' },
          { key: 'codigo', label: 'Nº do Contrato', sortable: true },
          { key: 'nomeCompleto', label: 'Nome' },
          { key: 'matricula', label: 'Matrícula' },
          { key: 'proposta.valorComIOF', label: 'Valor do Contrato', sortable: true },
          { key: 'valorParcela', label: 'Valor da Parcela', sortable: true },
          {
            key: 'proposta.dataAprovado',
            label: 'Data de início',
            formatter: (value) => formatarData(value),
            sortable: true,
          },
          { key: 'origem', label: 'Origem', sortable: true, formatter: (value) => (value ? value : 'Administração') },
          'statusFinanceiro',
        ],
        borderless: true,
        status: [
          {
            Consolidado: 'success',
            Pendente: 'warning',
            Quitado: 'info',
            Cancelado: 'danger',
            BloqueadoPendente: 'danger',
            BloqueadoConsolidado: 'danger',
            PendenteDeDocumento: 'primary',
            PendenteDePagamento: 'secondary',
          },
        ],
        items: [],
        vouPesquisar: false,
        contrato: null,
        usersUrl: 'https://jsonplaceholder.typicode.com/users',
        photosUrl: 'https://jsonplaceholder.typicode.com/photos',
        acesso: {
          FinanceiroContratosConsulta: false,
          FinanceiroContratosPendentes: false,
          FinanceiroContratosConsolidados: false,
        },
        chartContratosConsolidados: '0',
        chartTotalRemessas: '0',
        chartLucroLiquido: '0',
        chartTotalJuros: '0',
        isLoadingIndicadores: false,
      }
    },
    computed: {
      sortOptions() {
        return this.fields.filter((f) => f.sortable).map((f) => ({ text: f.label, value: f.key }))
      },
    },
    mounted() {
      this.carregaIndicadores()
      if (this.userData.role == 'master') {
        this.acesso.FinanceiroContratosConsulta = true
        this.acesso.FinanceiroContratosPendentes = true
        this.acesso.FinanceiroContratosConsolidados = true
      } else {
        this.acesso.FinanceiroContratosConsulta = this.userData.GrupoAcesso.FinanceiroContratosConsulta
        this.acesso.FinanceiroContratosPendentes = this.userData.GrupoAcesso.FinanceiroContratosPendentes
        this.acesso.FinanceiroContratosConsolidados = this.userData.GrupoAcesso.FinanceiroContratosConsolidados
      }
    },
    methods: {
      formatarStatus(status) {
        switch (status) {
          case 'BloqueadoConsolidado':
            return 'Suspensão Judicial'
          case 'BloqueadoPendente':
            return 'Suspensão Judicial'
          case 'PendenteDeDocumento':
            return 'Pendente Documento'
          case 'PendenteDePagamento':
            return 'Pendente Pagamento'
          case 'SeguradoExonerado':
            return 'Segurado Exonerado'
          case 'SeguradoFalecido':
            return 'Segurado Falecido'
          default:
            return status
        }
      },
      carregaIndicadores() {
        this.isLoadingIndicadores = true

        const param = {
          institutoId: this.userData.institutoSelecionado,
        }
        useJwt
          .post('financeiro/GetIndicadoresContratos', param)
          .then((result) => {
            this.chartContratosConsolidados = result.data.chartContratosConsolidados
            this.chartTotalRemessas = result.data.chartTotalRemessas
            this.chartLucroLiquido = result.data.chartLucroLiquido
            this.chartTotalJuros = result.data.chartTotalJuros
            this.isLoadingIndicadores = false
          })
          .catch((error) => {
            console.error(error)
            this.isLoadingIndicadores = false
            this.$message.error('Erro ao consultar indicadores!')
          })
      },
      reloadContratoContrato(itemId) {
        this.$refs['modal-contrato'].hide()
        this.contratosPendentes(itemId)
        this.carregarGrid()
      },
      carregarGrid() {
        this.isBusy = true
        useJwt
          .pesquisar('financeiro/GetpesquisarContrato', {
            ...this.pesquisar,
            institutoSelecionado: this.userData.institutoSelecionado,
          })
          .then((response) => {
            this.isBusy = false
            this.items = response.data.dados
            this.totalRows = response.data.total
            this.pesquisar = { ...this.pesquisar, currentPage: 1 }

            if (this.items.length == 0) {
              this.$message.error('Não foram encontrados contratos!')
            }
          })
          .catch((error) => {
            this.isBusy = false
            console.error(error)
          })
      },
      contratosPendentes(item) {
        this.$root.$emit('atualizarProtocoloPendente', true)
        useJwt
          .get(`financeiro/${item}`)
          .then((response) => {
            this.contrato = {
              ...response.data,
              contratoItemId: item,
            }

            if (this.contrato.proposta.tipoCalculo == 'posFixado') {
              this.contrato['apresentaPosFixado'] = true
            } else {
              this.contrato['apresentaPosFixado'] = false
            }
            this.$refs['modal-contrato'].show()
          })
          .catch((error) => {
            console.error(error)
          })
      },
      contratosConsolidados(item) {
        useJwt
          .get(`financeiro/${item}`)
          .then((response) => {
            this.contrato = {
              ...response.data,
              contratoItemId: item,
            }

            if (this.contrato.proposta.tipoCalculo == 'posFixado') {
              this.contrato['apresentaPosFixado'] = true
            } else {
              this.contrato['apresentaPosFixado'] = false
            }
            this.$refs['modal-contrato'].show()
          })
          .catch((error) => {
            console.error(error)
          })
      },
      cancelarContrato(item) {
        this.$swal({
          title: 'Atenção!',
          text: 'Deseja realmente cancelar o contrato?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Sim!',
          cancelButtonText: 'Não!',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-danger ml-1',
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.value) {
            useJwt
              .post(`financeiro/putCancelarContratoPendente`, {
                id: item.id,
                propostaId: item.propostaId,
              })
              .then((response) => {
                this.carregarGrid()
              })
              .catch((error) => {
                console.error(error)
              })
          }
        })
      },
      carregaOpcoesDeStatus() {
        let opcoesDeStatusDeAcordoComPermissao = [...this.statusOpcoes]

        if (!this.acesso.FinanceiroContratosPendentes) {
          opcoesDeStatusDeAcordoComPermissao = opcoesDeStatusDeAcordoComPermissao.filter((opcao) => opcao.value !== 'Pendente')
        }

        if (!this.acesso.FinanceiroContratosConsolidados) {
          opcoesDeStatusDeAcordoComPermissao = opcoesDeStatusDeAcordoComPermissao.filter(
            (opcao) => opcao.value !== 'Consolidado',
          )
        }

        return opcoesDeStatusDeAcordoComPermissao
      },
      hardcodedSeries(data) {
        return [
          {
            name: '',
            data: data.map((d) => ({ x: '', y: d })),
          },
        ]
      },
    },
  }
</script>

<style lang="scss"></style>
